.imoveis {
  div.wrapper {
    margin: 0 auto 30px;
    max-width: 85.375rem;
    position: relative;

    padding: 0px 16px;
    // display: flex;
    // flex-direction: column;

    @media (max-width: 767px) {
      align-items: center;
      padding: 12px 10px 20px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 12px 10px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        align-self: flex-start;
        width: 100%;
        position: relative;
        margin-bottom: 15px;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
      }

      a h2 {
        font-size: 32px;
        font-weight: bold;
        color: #6a8f21;
        margin: 0 20px 0 0;

        @media (max-width: 767px) {
          font-size: 21px;
        }
      }

      a img {
        height: 30px;

        @media (max-width: 767px) {
          height: 30px;
          width: 30px;
          object-fit: cover;
          object-position: left;
        }
      }

      .options {
        display: flex;

        @media (max-width: 767px) {
          display: none;
        }

        a {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
        }

        a+a {
          margin-left: 20px;
        }
      }
    }

    .body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      @media (max-width: 767px) {
        grid-template-columns: 100%;
        gap: 20px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }

      a {
        text-decoration: none;

        @media (min-width: 768px) and (max-width: 1024px) {
          &:last-child {
            display: none;
          }
        }
      }

      span {
        color: #6a8f21;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: 767px) {
          float: left;
          margin-bottom: -15px;
          margin-left: 110px;
        }
      }

      figure {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
          flex-direction: row;
        }

        &+figure {
          margin-left: 30px;
        }

        a {
          display: contents;
        }

        img {
          width: 309px;
          height: 167px;
          object-fit: cover;

          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
            margin-right: 10px;
          }
        }

        figcaption {
          display: flex;
          flex-direction: column;

          @media (max-width: 768px) {
            padding-top: 19px;
          }

          p {
            margin: 5px 0;
            font-weight: bold;
            font-size: 16px;

            @media (max-width: 768px) {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
