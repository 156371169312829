.section {
  &.-bg {
    background: #eee;
    padding: 30px 0;
    margin-bottom: 30px;

    .-wrapper {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      margin: 0;
      padding: 0;
    }
  }

  &.-wrapper {
    @media (max-width: 991px) {
      padding: 1.5rem 0.625rem;
    }

    @media (min-width: 992px) {
      margin: 0 auto 30px;
      max-width: 85.375rem;
      padding: 0 16px;
    }

    &.noticias {
      padding-bottom: 0;
    }
  }

  .colunistas {
    .-wrapper {
      padding: 21px 16px 31px;

      @media (max-width: 768px) {
        padding: 21px 11px 31px;
      }
    }
  }

  &.-header {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    margin-bottom: 1.875rem;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 767px) {
      margin-bottom: 1rem;
      grid-template-columns: 70% 30%;
    }
  }

  &.-menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 991px) {
      display: none;
    }

    &.-aberto {
      display: block;
      cursor: pointer;
      color: #505d68;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0.75rem;
      position: absolute;
      top: 3rem;
      right: 0;
      z-index: 3;
      background: #fff;
      border: 1px solid #c2d1d9;
      min-width: 15rem;
      border-radius: 0.25rem;

      &::after {
        content: "";
        display: block;
        width: 0.625rem;
        height: 0.625rem;
        border-top: 1px solid #c2d1d9;
        border-right: 1px solid #c2d1d9;
        transform: rotate(-45deg);
        position: absolute;
        right: 0.75rem;
        top: -0.4rem;
        background: #fff;
      }
    }
  }

  &.-trigger {
    @media (min-width: 768px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      border: 0;
      line-height: 4px;
      padding: 1rem 1.45rem;
    }
  }

  &.-item {
    display: inline-block;
    list-style: none;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width: 767px) {
      &:not(:first-child) {
        padding-top: 0.5rem;
      }
    }

    @media (min-width: 768px) {
      &:not(:first-child) {
        padding-left: 1rem;
      }
    }

    .-aberto & {
      display: block;
    }
  }

  &.-title {
    font-size: 2rem;
    font-weight: 700;

    @media (max-width: 991px) {
      font-size: 1.375rem;
    }
  }

  &.-subtitle {
    font-size: 1.125rem;
    font-weight: bold;
    color: #333;
    margin: 0 0 1rem;
  }

  &.-logo {
    &.-xs {
      display: inline-block;
      height: 1.67em;
      width: 1.67em;

      @media (min-width: 426px) {
        display: none;
      }
    }

    &.-md {
      display: inline-block;
      height: 0.9375em;

      .noticias & {
        height: 0.84em;
      }

      @media (max-width: 425px) {
        display: none;
      }
    }
  }

  &.-grid {
    display: grid;
    gap: 0 2em;
    grid-template-areas: "maingroup maingroup group2 group3" "blog blog blog blog";
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1024px) {
      gap: 0 1.25rem;
      grid-template-areas: "maingroup" "group2" "group3" "blog";
      grid-template-columns: 100%;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 0 1em;
    }
  }

  &.-maingroup {
    grid-area: maingroup;
    display: grid;
    gap: 0 2em;
    grid-template-areas: "medium medium" ". .";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    @media (max-width: 767px) {
      gap: 0 1.25rem;
      grid-template-areas: "medium" "." ".";
      grid-template-columns: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      gap: 0 1.25rem;
    }
  }

  &.-group2,
  &.-group3 {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: grid;
      gap: 0 1.25rem;
      grid-template-areas: ". .";
      grid-template-columns: repeat(2, 1fr);

      .-small:last-child {
        display: none;
      }
    }
  }

  &.-group2 {
    grid-area: group2;
  }

  &.-group3 {
    grid-area: group3;

    @media (min-width: 768px) and (max-width: 1024px) {
      .-ad {
        grid-column: 1 / span 2;
        order: 1;
      }

      .-small {
        order: 2;
      }
    }
  }

  &.-blog {
    border-top: 1px solid #c2d1d9;
    padding-top: 2em;
    grid-area: blog;
    display: grid;
    gap: 0 2em;
    grid-template-areas: ". . . .";
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      gap: 0;
      grid-template-columns: 100%;
      grid-template-areas: "." "." "." ".";
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "." ".";
      gap: 2rem;
    }
  }

  &.news,
  &.-ad {
    margin-bottom: 1.875rem;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &.-blog &.news {
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &.-small {
    overflow: hidden;

    .-img {
      float: left;
      margin: 0 0.625rem 0 0;
    }

    .-area,
    .-title {
      flex: 0 0 50%;
      margin: 0;
    }

    .-area {
      margin: 0 0 0.25em;
    }
  }

  &.-medium {
    grid-area: medium;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #c2d1d9;

    .-img {
      width: 100%;
    }

    .-area {
      padding: 0.75rem 0 0;
    }

    .-title {
      display: inline-block;
      padding: 0 0.75rem 18px 1.5rem;
      margin: 0;
      line-height: 1.43;

      @media (max-width: 768px) {
        padding: 0 0 0.75rem 0.625rem;
        font-size: 21px;
        line-height: normal;
      }
    }
  }
}

.-trigger {
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 991px) {
    display: block;
    background-color: transparent;
    border: 0;
    line-height: 4px;
    padding: 1rem 1.5rem;
  }
}

.trigger {
  pointer-events: none;

  &.-ellipsis {
    position: relative;

    &,
    &::before,
    &::after {
      background-color: #333;
      border-radius: 2px;
      content: "";
      display: inline-block;
      height: 4px;
      width: 4px;
    }

    &::before,
    &::after {
      position: absolute;
      top: calc(50% - 2px);
    }

    &::before {
      left: 8px;
    }

    &::after {
      left: 16px;
    }
  }
}

.section.colunistas {
  background-color: #eee;

  @media (min-width: 767px) {
    margin: 30px 0;
  }

  .tns-outer {
    margin-bottom: 20px;
  }

  .section.-header {
    margin-bottom: 20px;
  }

  .colunistas {
    .news {
      margin-bottom: 0;

      @media (max-width: 768px) {
        &+article {
          margin-top: 20px;
        }
      }
    }

    &.-grid {
      display: grid;
      gap: 0 2em;
      grid-template-areas: ". . . .";
      grid-template-columns: repeat(4, 1fr);
      margin: 0 0 11px;

      @media (max-width: 767px) {
        gap: 0;
        grid-template-columns: 1fr;
        grid-template-areas: "." "." ".";
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        margin: 0;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: ". .";
      }
    }
  }
}

.members {
  .noticias & {
    margin-bottom: 1.875rem;

    .btn {
      display: block;
      width: 100%;
    }
  }

  .colunistas & {
    text-align: center;

    .btn {
      width: 100%;
      max-width: 310px;
    }
  }
}

.front-page {
  margin-bottom: 30px;

  &:after {
    content: "";
    clear: both;
    display: block;
  }

  img {
    height: 198px;
    object-fit: cover;
    width: 100%;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: #d8d8d8;
  }

  .-link {
    float: right;
  }

  .btn.-link {
    padding: 0;
    margin-top: 10px;
  }
}

.region_favoritos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto 30px;
  max-width: 85.375rem;

  .uai-ads.-wrapper {
    grid-area: 2 / 1 / 3 / 3;
    margin: 0;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;

    .uai-ads.-wrapper {
      grid-area: unset;
    }
  }

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    display: block;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
