.topicos {
  .wrapper {
    margin: 0 auto 30px;
    max-width: 85.375rem;
    position: relative;

    padding: 0px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;

    @media (max-width: 767px) {
      grid-template-columns: 100%;
      gap: 20px;
      padding: 12px 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 12px 10px;
    }

    section {
      width: 100%;
      max-width: 423px;
      margin: 0 auto;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      h2 {
        margin: 0;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          font-size: 21px;
          margin-bottom: 15px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 23px;
        }
      }

      figure {
        margin: 0;

        img {
          @media (max-width: 767px) {
            width: 100%;
          }
        }

        figcaption {
          background-color: #eee;
          padding: 10px;

          @media (min-width: 1025px) {
            height: 130px;
            padding: 20px;
          }
        }

        figcaption span {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;

          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 12px;
          }
        }

        figcaption p {
          font-size: 18px;
          font-weight: bold;
          color: #333;

          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 16px;
          }
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        li:first-child {
          margin-top: 0;
        }

        li:last-child {
          border-bottom: 1px solid #c2d1d9;
          padding-bottom: 5px;
        }

        >li {
          border-top: 1px solid #c2d1d9;
          padding-top: 5px;
          margin-top: 5px;
          min-height: 50px;
        }

        li a {
          font-size: 15px;
          font-weight: 300;
          flex: 100%;

          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 13px;
          }
        }

        li svg {
          fill: #c2d1d9;
          transform: rotate(-90deg);
          margin-left: 18px;
        }

        li span.sprite {
          margin-left: 18px;
        }
      }

      & h2,
      & span {
        color: #1973b4;
      }

      &.saude-plena h2,
      &.saude-plena span {
        color: #4ac5a9;
      }

      &.comportamento h2,
      &.comportamento span {
        color: #ed7d00;
      }
    }
  }
}
