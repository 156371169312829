.black-friday,
.uai-ads {
  &.-wrapper {
    margin: 30px auto;
    max-width: 85.375rem;
  }

  .black-friday, .uai-ads {
    padding: 0 16px;
    margin: 0 auto;

    @media (max-width: 767px) {
      padding: 0 10px;

      .content + .content {
        display: none;
      }
    }

    > .-title {
      font-size: 2rem;
      font-weight: bold;

      margin: 0;
    }

    .main-content,
    .content {
      display: flex;

      article + article {
        margin-left: 29px;
      }

      @media (max-width: 767px) {
        flex-direction: column;

        article + article {
          margin-left: 0px;
        }
      }

      @media (min-width: 1025px) and (max-width: 1280px) {
        article + article {
          margin-left: 20px;
        }
      }
    }

    .main-content {
      article figure .-title {
        font-weight: bold;
      }

      @media (max-width: 767px) {
        flex-direction: column;

        article + article {
          margin-top: 30px;
        }

        article:nth-child(n + 3) {
          display: none;
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        article:nth-child(n + 3) {
          display: none;
        }
      }
    }

    .content {
      margin-top: 30px;

      article figure {
        display: flex;
      }

      article figure img {
        max-width: 140px;
        max-height: 140px;
        margin: 0 10px 0 0;
      }

      @media (max-width: 767px) {
        flex-direction: column;

        article + article {
          margin-top: 20px;
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;

        article + article {
          margin-left: 0px;
        }

        article {
          flex: 0 0 50%;
          max-width: 50%;
        }

        article:nth-child(n + 3) {
          margin-top: 30px;
        }
      }
    }
  }
}
