.agenda-cultural {
  &.-bg {
    @media (max-width: 768px) {
      background: #eeeeee;
      margin-bottom: 20px;
    }
  }

  &.-header {
    @media (max-width: 425px) {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      grid-template-columns: 100%;
    }
  }

  &.-title,
  .-area {
    color: #f82a11;
  }

  &.section.-title {
    display: flex;
    align-items: center;
  }

  &.section.-title i.logo {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/logo-soubh.svg");
    background-size: contain;
    padding: 15px 40px;
    margin-left: 20px;
  }

  .-title {
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }

  .-logo {
    @media (max-width: 767px) {
      height: 20px;
    }

    @media (min-width: 768px) {
      height: 30px;
    }
  }

  .tns-outer {
    margin: 0 -16px;
  }

  .filters {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.filters {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;

    select {
      width: 100%;
    }
  }

  .tns-outer {
    margin-bottom: 0;
  }
}

.filters-days {
  position: relative;
  padding: 0 2.5rem;
  margin: 0 1rem;
  max-width: 420px;

  @media (max-width: 767px) {
    max-width: calc(100vw - 1.5rem);
    margin: 1em 0;
  }

  [data-controls] {
    background-color: transparent;
    border: 0;
    color: #333;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    padding: 0;
  }

  [data-controls="prev"] {
    left: 0;
  }

  [data-controls="next"] {
    right: 0;
  }
}

.days-item {
  &.-input {
    position: absolute;
    top: -99px;
    left: -99px;
    width: 1px;
    height: 1px;
  }

  &.-label {
    background-color: #c2d1d9;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.25rem 0 0;
    line-height: 1rem;

    span {
      display: block;
      font-size: 1rem;
    }
  }

  &.-input:checked + &.-label {
    background-color: #f82a11;
    color: #fff;
  }
}
