.tv-alterosa {
  .section.-small .-img {
    float: none;
  }

  .section.-title,
  .-area {
    color: #b52614;
  }

  &.section.-title {
    display: flex;
    align-items: center;
  }

  &.section.-title i.logo {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/alterosa.png");
    background-size: cover;
    padding: 19px 19px;
    margin-left: 20px;
  }

  .-logo {
    @media (max-width: 767px) {
      height: 1.25rem;
      margin-left: 20px !important;
    }

    @media (min-width: 768px) {
      height: 2.375rem;
    }
  }

  .-js-tabs {
    &.-button {
      &:hover {
        background-color: rgba(#b52614, 0.15);
      }
    }

    &.-active {
      color: #fff;
      background-color: #b52614;
    }
  }

  &.section.-header {
    grid-template-columns: 21% 79%;

    @media (max-width: 768px) {
      grid-template-columns: 60% 40%;
    }
  }

  &.-grid {
    gap: 1.25rem;
    grid-template-areas: "featured" "." "." "." "ad";
    grid-template-columns: 100%;

    @media (min-width: 768px) and (max-width: 1024px) {
      gap: 2rem;
      grid-template-columns: 1.75fr 1.25fr;
      grid-template-areas: "featured ." "featured ." "featured .";
      grid-template-rows: repeat(2, max-content);
    }

    @media (min-width: 1025px) {
      gap: 2rem;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-areas: "featured . ." "featured . ad";
    }
  }

  .-medium {
    grid-area: featured;
    border: 0;
    position: relative;

    .-area {
      margin: 0.75rem 0;
    }

    .-overlay::before {
      content: "";

      background-color: rgba(0, 0, 0, 0.5);

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .-overlay::after {
      content: "";
      background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/play.svg");
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);

      width: 100px;
      height: 100px;
      z-index: 2;
    }

    .-overlay {
      position: relative;
      height: 503px;
      background: #eee;

      @media (max-width: 768px) {
        height: 220px;
      }
    }

    .-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .-title {
      @media (max-width: 767px) {
        font-size: 1rem;
        display: block;
        line-height: 1.31;
      }
    }

    .-area,
    .-title {
      padding: 0;
    }
  }

  .news {
    margin: 0;

    &.-area {
      margin: 0.25rem 0;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    figcaption {
      @media (max-width: 767px) {
        order: 2;
      }
    }

    p {
      font-weight: 700;
      margin: 0;
    }

    &.-small {
      position: relative;

      .-overlay::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .-overlay::after {
        content: "";
        background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/play.svg");
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);

        width: 75px;
        height: 75px;
        z-index: 2;

        @media (max-width: 768px) {
          width: 29px;
          height: 29px;
        }
      }

      .-area {
        margin: 3px 0 0;
      }

      .-overlay {
        position: relative;
        height: 220px;

        @media (max-width: 768px) {
          height: 71px;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          float: left;
          object-fit: cover;
          width: 100px;
          height: 100px;
        }
      }

      .-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      figcaption {
        @media (min-width: 768px) and (max-width: 1024px) {
          float: right;
          width: calc(100% - 100px - 0.625rem);
        }
      }
    }
  }

  .-ad {
    margin: 0;

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-column: 1 / span 2;
    }
  }

  &.-img {
    width: 100%;
  }
}
