/**
* base/_main.scss
*/

/* ==========================================================================
  Imports
  ========================================================================== */

// @import "";
.no-scrolling {
  overflow: hidden;
}

a .-area {
  color: #1973b4;
}



.btn {
  border: 0;
  color: #333;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 0.8rem 1.5rem;
  border-radius: 0.25rem;
  border: 0;
  cursor: pointer;

  &.-assinatura {
    background-color: #ffba00;
  }

  &.-padrao {
    background-color: #1973b4;
    color: #fff;
  }

  &.-link {
    font-size: 0.875rem;
    font-weight: bold;
    color: #333;
  }
}

select {
  color: #9b9b9b;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.625rem 0.6875rem;
  appearance: none;
  background: #fff url("https://www.uai.com.br/imgs/uai/v1-2-2/chevron-down.svg") no-repeat
    calc(100% - 0.5rem) 50%;
  border-radius: 0.25rem;
  border: 1px solid #333;
}

.custom-select {
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 190px;

  @media (max-width: 767px) {
    width: 95%;
  }

  &:focus {
    max-height: calc(2.5rem + 10px);
    pointer-events: all;
    z-index: 99;
  }
}

.custom-option {
  list-style: none;

  &__input {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0;
    font-size: 0;
    list-style: none;
  }

  &__label {
    list-style: none;
    cursor: pointer;
    color: #505d68;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 0.5rem 0.75rem;
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    order: 2;
    z-index: 1;
    background: #fff;
    pointer-events: none;
    border-left: 1px solid #c2d1d9;
    border-right: 1px solid #c2d1d9;

    &:nth-child(2) {
      border-top: 1px solid #c2d1d9;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom: 1px solid #c2d1d9;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      color: #1973b4;
    }

    .region.section &:hover {
      color: #002559;
    }

    .agenda-cultural &:hover {
      color: #f82a11;
    }

    .custom-select:focus & {
      position: relative;
      pointer-events: all;
    }
  }

  &__input:checked + &__label {
    order: 1;
    z-index: 2;
    position: relative;
    margin-bottom: 0.625rem;

    background: #fff url("https://www.uai.com.br/imgs/uai/v1-2-2/dropdown.svg") no-repeat
      calc(100% - 0.5rem) 50%;
    border-radius: 0.25rem;
    border: 1px solid #333;
    padding: 0.625rem 0.6875rem;
    color: #9b9b9b;

    &:first-of-type
      + .custom-option__input
      + .custom-option__label:nth-child(4) {
      border-top: 1px solid #c2d1d9;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.d-none {
  display: none;

  &-xs {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &-sm {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: none;
    }
  }

  &-md {
    @media (min-width: 1025px) and (max-width: 1280px) {
      display: none;
    }
  }

  &-lg {
    @media (min-width: 1281px) {
      display: none;
    }
  }
}

.d-block {
  display: block;

  &-xs {
    @media (max-width: 767px) {
      display: block;
    }
  }

  &-sm {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: block;
    }
  }

  &-md {
    @media (min-width: 1025px) and (max-width: 1280px) {
      display: block;
    }
  }

  &-lg {
    @media (min-width: 1281px) {
      display: block;
    }
  }
}

.d-flex {
  display: flex;

  &-xs {
    @media (max-width: 767px) {
      display: flex;
    }
  }

  &-sm {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: flex;
    }
  }

  &-md {
    @media (min-width: 1025px) and (max-width: 1280px) {
      display: flex;
    }
  }

  &-lg {
    @media (min-width: 1281px) {
      display: flex;
    }
  }
}

.d-md {
  @media (min-width: 768px) {
    &-block {
      display: block;
    }

    &-none {
      display: none;
    }
  }
}

.-js-tabs {
  &.-button {
    @media (max-width: 767px) {
      padding: 0;
    }

    @media (min-width: 768px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

figure {
  margin: 0;
  padding: 0;

  span {
    color: #185a9d;
  }

  &.entretenimento {
    span {
      color: #ed7d00;
    }
  }

  &.saude {
    span {
      color: #4ac5a9;
    }
  }

  &.famosos {
    span {
      color: #e91e63;
    }
  }

  &.noticias {
    span {
      color: #1973b4;
    }
  }

  &.musica {
    span {
      color: #4bae4f;
    }
  }
}
