.uai-ads {

  .uai-ads {
    >.-title {
      font-size: 10px;
      color: #404040;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;

      margin: 0;
    }


    .main-content {
      padding: 20px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;

      article {
        &+article {
          // margin-left: 4.875rem;
          margin-left: 6.109%;
        }

        figure {
          .-title {
            display: inline-block;
            text-transform: uppercase;
            font-size: 12px;
            color: #404040;
            max-height: 1.813rem;
            overflow: hidden;
          }

          .-description {
            font-size: 12px;
            color: #00000080;
          }
        }

        .news.-img,
        figcaption {
          max-width: 150px;
          max-height: 100px;
        }
      }



      @media (max-width: 767px) {
        flex-direction: column;
        padding: 10px;

        article {
          figure {
            display: flex;
          }

          .news.-img {
            margin: 0 10px 0 0;
          }

          .news.-img {
            max-width: 135px;
            max-height: 90px;
          }

          figcaption {
            max-width: 100%;
            max-height: 90px;
          }


          .-description {
            max-height: 2.938rem;
            overflow: hidden;
          }
        }

        article+article {
          margin-left: 0;
          margin-top: 10px;
        }

        article:nth-child(n + 3) {
          display: block;
        }


      }

      @media (min-width: 768px) and (max-width: 1024px) {
        article:nth-child(n + 3) {
          display: block;
        }
      }
    }


  }
}
