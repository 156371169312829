.noticias {

  &.-title,
  .-area {
    color: #002559;
  }

  &.-title {
    display: flex;
    align-items: center;
  }

  &.section.-title i.logo {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/logo-em-azul-2022.svg");
    background-size: contain;
    padding: 10.65px 100px;
    margin-left: 20px;

    @media (max-width: 768px) {
      background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/em.svg");
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      padding: 0;
    }
  }

  &.section.-title i.logo-aqui {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/logo-aqui.png");
    background-size: contain;
    padding: 10.65px 22px;
    margin-left: 20px;

    @media (max-width: 768px) {
      background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/icon-aqui.png");
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      padding: 0;
    }
  }

  .section.-grid a .-area,
  .section.-grid .section.-blog a .-area {
    color: #002559;
  }

  .section.-maingroup,
  .section.-group2,
  .section.-group3 {

    a[href*="blogs.uai"] i.icone-site,
    a[href*="parceiros.uai"] i.icone-site {
      background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/em.svg");
    }
  }

  .section.-grid a[href*="aqui.uai.com.br"] .-area {
    color: #ff0705;
  }


  .section {
    .news.-small {
      max-height: 100px;

      .-title {
        line-height: normal;
      }
    }
  }

  .-maingroup {
    .section.-medium {
      margin-bottom: 30px;

      .-img {
        max-height: 366px;
      }

      .-area {
        padding-top: 20px;
      }
    }
  }

  .section.-group2 {

    .ad,
    .-ad {
      min-height: 260px;
      max-height: 280px;
    }

    .news.-small {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }

  .section.-group3 {
    .members {
      h3.section.-subtitle {
        margin-bottom: 20px;
      }

      .news.-small {
        margin-bottom: 20px;
      }


      a[href*="em.com.br"] i.icone-site,
      a.news i.icone-site {
        @extend %news-area;

        background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/assinantes.svg");
        color: #002559;
      }

    }
  }

  .section {
    .news.-small {
      max-height: 100px;
    }
  }
}
