.entretenimento {
  &.-title,
  .-area {
    color: #ed7d00;
  }

  &.-title {
    display: flex;
    align-items: center;
  }

  .section.-grid .section.news a[href*="soubh.uai.com.br"] i.icone-site {
    display: none;
    padding-left: 0;
    background-image: none;
  }

  .section.-grid .section.news a[href*="betnabola"] i.icone-site {
    display: none;
    padding-left: 0;
    background-image: none;
  }

  &.section.-title i.logo {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/emais.svg");
    padding: 14px;
    margin-left: 20px;
    background-size: cover;
  }

  .section.-grid a .-area {
    color: #ed7d00;
  }

  .section {
    .news.-small {
      max-height: 100px;

      .-title {
        line-height: normal;
      }
    }
  }

  .section.-maingroup,
  .section.-group2,
  .section.-group3  {
    a[href*="blogs.uai"] i.icone-site,
    a[href*="parceiros.uai"] i.icone-site {
      background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/emais.svg");
    }
  }



  .-maingroup {
    .section.-medium {
      margin-bottom: 30px;

      .-img {
        max-height: 366px;
      }

      .-area {
        padding-top: 20px;
      }
    }
  }

  .section.-group3 {

    article:nth-child(1),
    article:nth-child(2) {
      max-height: 90px;

      @media (max-width: 768px) {
        max-height: 95px;
      }

      .-img {
        width: 90px;
        height: 90px;
      }
      .news.-title {
        font-size: 15px;
        margin-top: -4px;
      }
    }
  }
}
