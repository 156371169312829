// components/_parceiros.scss
.parceiros {
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;

  padding: 20px 20px 30px;

  @media (max-width: 767px) {
    padding: 20px 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      text-transform: capitalize;
    }

    a {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) and (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 2rem;

      .ad {
        grid-column: 1 / span 2;
      }
    }

    a:nth-child(n + 5) {
      display: none;
    }

    a {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      a:nth-child(4) {
        display: none;
      }

      a {
        margin-bottom: 20px;
      }
    }

    figure {
      margin: 0;
      display: flex;

      img {
        width: 100px;
        height: 100px;
        border-radius: 2px;
        margin-right: 10px;
        object-fit: cover;
      }

      figcaption {
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          color: #8b572a;
          font-weight: bold;
          text-transform: uppercase;
          margin-top: 3px;
        }

        p {
          margin: 7px 0 0;
          font-size: 16px;
          color: #333;
          max-height: 4rem;
          overflow: hidden;
        }
      }
    }
  }
}

.sprite {
  &.-right {
    height: 1em;
    fill: currentColor;
    width: 1em;
    margin: 0 0 4px 3px;
  }
}

%news-area {
  display: block;
  padding-left: 1.5rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 1.67em;
  min-height: 1.67em;
  overflow: hidden;

  .section.-medium & {
    padding-left: 2.5rem !important;
    padding-top: 0;
    background-position: 0.75rem 100%;
  }
}

a[href*="https://uai.com.br"] .-area
{
  color: #1973b4;
}

:is(a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
  i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/parceiro.svg");
  color: #8b572a;
}

:is(a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
  .-area {
  color: #8b572a;
}

a[href*="blogs.uai"] i.icone-site,
a[href*="parceiros.uai"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/parceiro.svg");
  color: #8b572a;
}

a[href*="blogs.uai"] .-area,
a[href*="parceiros.uai"] .-area {
  color: #8b572a;
}

.menu.-item:hover a[href*="parceiros.uai.com.br"] {
  color: #8b572a;
}

.menu.-item:hover a[href*="uai.com.br/blogs"] {
  color: #8b572a;
}

a[href*="em.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/em.svg");
  color: #002559;
}

a[href*="em.com.br"] .-area {
  color: #002559;
}

a[href*="noataque.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/no-ataque.svg");
  color: #088264;
}

a[href*="noataque.com.br"] .-area {
  color: #088264;
}

a[href*="https://musica.uai.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/logo-uai.svg");
  color: #560CAD;
}

a[href*="https://musica.uai.com.br"] .-area {
  color: #560CAD;
}

a[href*="uai.com.br/app/noticia/negocios"] i.icone-site,
a[href*="uai.com.br/negocios"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/logo-uai.svg");
  color: #1973b4;
}

a[href*="uai.com.br/app/noticia/negocios"] .-area,
a[href*="uai.com.br/negocios"] .-area {
  color: #1973b4;
}

a[href*="mg.superesportes"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/se.svg");
  color: #00660d;
}

a[href*="mg.superesportes"] .-area {
  color: #00660d;
}

a[href*="vrum.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/vrum.svg");
  color: #b52614;
}

.menu.-item:hover a[href*="vrum.com.br"],
a[href*="vrum.com.br"] .-area {
  color: #b52614;
}

a[href*="estadodeminas.lugarcerto"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/lugarcerto.svg");
  color: #6a8f21;
}

.menu.-item:hover a[href*="estadodeminas.lugarcerto"],
a[href*="estadodeminas.lugarcerto"] .-area {
  color: #6a8f21;
}

a[href*="uai.com.br/saude"] i.icone-site,
a[href*="app/noticia/saude"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/saudeplena.svg");
  color: #4ac5a9;
}

.menu.-item:hover a[href*="uai.com.br/saude"],
a[href*="uai.com.br/saude"] .-area,
a[href*="app/noticia/saude"] .-area {
  color: #4ac5a9;
}

.menu.-item:hover a[href*="uaiagro.com.br"] {
  color: #1fb51a;
}

a[href*="betnabola"] i.icone-site {
  @extend %news-area;
  color: #026704;
}

.menu.-item:hover a[href*="betnabola"],
a[href*="betnabola"] .-area {
  color: #026704;
}

:is(a[href*="uai.com.br/entretenimento"], a[href*="uai.com.br/app/noticia/famosos"], a[href*="uai.com.br/app/noticia/comportamento"], a[href*="uai.com.br/app/noticia/series-e-tv"], a[href*="uai.com.br/app/noticia/musica"], a[href*="uai.com.br/app/noticia/trends"], a[href*="uai.com.br/app/divirta-se"], a[href*="uai.com.br/app/noticia/cinema"], a[href*="uai.com.br/app/cinema"], a[href*="uai.com.br/app/noticia/mexerico"], a[href*="uai.com.br/app/noticia/games"], a[href*="uai.com.br/app/noticia/teatro"], a[href*="uai.com.br/app/noticia/e-mais"], a[href*="uai.com.br/app/noticia/artes-e-livros"], a[href*="uai.com.br/app/noticia/gastronomia"])
  i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/emais.svg");
}

:is(a[href*="uai.com.br/entretenimento"], a[href*="uai.com.br/app/noticia/famosos"], a[href*="uai.com.br/app/noticia/comportamento"], a[href*="uai.com.br/app/noticia/series-e-tv"], a[href*="uai.com.br/app/noticia/musica"], a[href*="uai.com.br/app/noticia/trends"], a[href*="uai.com.br/app/divirta-se"], a[href*="uai.com.br/app/noticia/cinema"], a[href*="uai.com.br/app/cinema"], a[href*="uai.com.br/app/noticia/mexerico"], a[href*="uai.com.br/app/noticia/games"], a[href*="uai.com.br/app/noticia/teatro"], a[href*="uai.com.br/app/noticia/e-mais"], a[href*="uai.com.br/app/noticia/artes-e-livros"], a[href*="uai.com.br/app/noticia/gastronomia"])
  .-area {
  color: #ed7d00;
}

a[href*="alterosa.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/alterosa.png");
  color: #b52614;
}

.menu.-item:hover a[href*="alterosa.com.br"],
a[href*="alterosa.com.br"] .-area {
  color: #b52614;
}

.gastronomia {
  a[href*="uai.com.br/app/noticia/"] .-area {
    color: #e65245;
  }

  a[href*="uai.com.br/"] .-area {
    color: #e65245;
  }

  &.news a.news[href*="uai.com.br"] .-area,
  &.news a.news[href*="uai.com.br/app/noticia/"] .-area,
  &.news a.news[href*="em.com.br"] .-area,
  &.news a.news[href*="em.com.br/app/noticia/"] .-area,
  &.news a.news .-area {
    color: #e65245;
  }
}

.negocios {
  a[href*="uai.com.br"] .-area {
    color: #1973b4;
  }
}

.menu.-item:hover a[href*="uai.com.br/negocios"] {
  color: #1973b4;
}

a[href*="soubh.uai.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/soubh.svg");
  color: #f92a11;
}

.menu.-item:hover a[href*="soubh.uai.com.br"],
a[href*="soubh.uai.com.br"] .-area {
  color: #f92a11;
}

a[href*="revistaencontro.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/encontro.svg");
  color: #e10109;
}

.menu.-item:hover a[href*="revistaencontro.com.br"],
a[href*="revistaencontro.com.br"] .-area {
  color: #e10109;
}

a[href*="aqui.uai.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/icon-aqui.png");
  color: #ff0705;
}

.menu.-item:hover a[href*="aqui.uai.com.br"],
a[href*="aqui.uai.com.br"] .-area {
  color: #ff0705;
}

a[href*="melhoresofertas.uai.com.br"] i.icone-site {
  @extend %news-area;

  background-image: url("https://www.uai.com.br/imgs/uai/v1-2-2/areas/melhores-ofertas.png");
  color: #28306d;
}

a[href*="melhoresofertas.uai.com.br"] .-area {
  color: #28306d;
}

.entretenimento {
  :is(a[href*="aqui.uai.com.br"], a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="melhoresofertas"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
    .-area {
    color: #ed7d00;
  }

  .-area {
    color: #ed7d00;
  }
}

.esportes {
  :is(a[href*="aqui.uai.com.br"], a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="melhoresofertas"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
    .-area {
    color: #00660d;
  }

  .-area {
    color: #00660d;
  }
}

.noticias {
  :is(a[href*="aqui.uai.com.br"], a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="melhoresofertas"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
    .-area {
    color: #002559;
  }

  .-area {
    color: #002559;
  }
}

.entretenimento,
.negocios,
.esportes {
  :is(a[href*="aqui.uai.com.br"], a[href*="alemdofato"], a[href*="avozdoconsumidor"], a[href*="basquetetododia"], a[href*="bhdicas"], a[href*="blimabracher"], a[href*="blogdovictormartins"], a[href*="victormartins"], a[href*="bolaprafrente"], a[href*="cinemanoescurinho"], a[href*="crismendonca"], a[href*="dropsdejogos"], a[href*="defatoonline"], a[href*="fashionistando"], a[href*="interessedeminas"], a[href*="lanoticia"], a[href*="lifestyle"], a[href*="maiseducacao"], a[href*="melhoresofertas"], a[href*="mercadohoje"], a[href*="moonbh"], a[href*="mundoela"], a[href*="neoradar"], a[href*="nerdizmo"], a[href*="nikoguru"], a[href*="omundoautista"], a[href*="paoecerveja"], a[href*="planetaamerica"], a[href*="projetometamorfose"], a[href*="quintaldodalai"], a[href*="saiadazonadeconforto"], a[href*="territoriosgastronomicos"], a[href*="thepolitica"], a[href*="ultimamarcha"], a[href*="victormartins"], a[href*="vozdoconsumidor"], a[href*="wemystic"], a[href*="uaiagro"], a[href*="gadz"], a[href*="guiabemviver"])
    i.icone-site {
    @extend %news-area;
    display: none;
  }
}


